import React, { useContext, useState } from "react"

import { IconButton, Typography } from "@suraasa/placebo-ui"

import { Edit } from "iconoir-react"

import ProfileContext from "views/Profile/context"

import CheckTile from "../CheckTile"

import PerksDialog from "./PerksDialog"

function Perks() {
  const { perks, isPublic } = useContext(ProfileContext)
  const [openPerksDialog, setOpenPerksDialog] = useState(false)
  return (
    <>
      <PerksDialog
        handleClose={() => setOpenPerksDialog(false)}
        open={openPerksDialog}
      />
      <div className="flex items-center justify-between">
        <Typography
          className="mb-3 mt-0.5"
          color="primary.500"
          variant="preTitle"
        >
          Perks
        </Typography>
        {!isPublic && (
          <IconButton color="primary" onClick={() => setOpenPerksDialog(true)}>
            <Edit />
          </IconButton>
        )}
      </div>

      {perks.data.length ? (
        <div className="flex flex-col flex-wrap sm:flex-row sm:justify-start">
          {perks.data.map(item => (
            <CheckTile key={item.id} label={item.name} />
          ))}
        </div>
      ) : (
        <Typography
          className="mb-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Add your school’s perks here
        </Typography>
      )}
    </>
  )
}

export default Perks
