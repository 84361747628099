import React from "react"

import {
  Dialog,
  DialogContent,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  menu: {
    display: "flex",
    zIndex: 98,
    width: "100vw",
    transform: "none",
    maxWidth: "100%",
  },

  container: {
    padding: 0,
  },

  childContainer: {
    background: theme.colors.common.white[500],
    position: "fixed",
    width: "100vw",
    bottom: 0,
    zIndex: 99,
    left: 0,
  },
})

function MobileBottomMenu({
  children,
  open,
  close,
}: {
  children: React.ReactNode
  open: boolean
  close: () => void
}) {
  const classes = useStyles()

  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  if (!open || smUp) return null

  return (
    <Dialog className={classes.menu} open={open} onRequestClose={close}>
      <DialogContent className={classes.container}>
        <div className={classes.childContainer}>{children}</div>
      </DialogContent>
    </Dialog>
  )
}

export default MobileBottomMenu
