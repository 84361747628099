import React from "react"

import { Amenity, Gallery, Perk, Profile } from "api/resources/profile/types"
import { UseArray } from "utils/hooks/useArray"

type ProfileItem<T> = {
  data: UseArray<T>["array"]
  add: UseArray<T>["unshift"]
  remove: UseArray<T>["removeByKey"]
  update: UseArray<T>["updateByKey"]
  set: UseArray<T>["set"]
}

type Context = {
  isPublic: boolean
  profile: Profile
  updateProfile: React.Dispatch<React.SetStateAction<Partial<Profile>>>
  gallery: ProfileItem<Gallery>
  amenities: ProfileItem<Amenity>
  perks: ProfileItem<Perk>
}

const defaultProfileItem = {
  data: [],
  add: () => {},
  remove: () => {},
  update: () => {},
  set: () => {},
}

const ProfileContext = React.createContext<Context>({
  isPublic: false,
  updateProfile: () => {},
  profile: {
    id: 0,
    schoolId: 0,
    name: "",
    branch: null,
    website: "",
    email: "",
    phoneNumber: null,
    schoolAdmin: {
      email: "",
      profile: { phoneNumber: { code: 0, number: 0 } },
    },
    address: "",
    description: "",
    logo: "",
    city: null,
    pincode: null,
    coverImage: "",
    dateEstablished: "",
    workingDays: [],
    dayStartTime: "",
    dayEndTime: "",
    teachingMode: 3,
    isVerified: false,
    isSalesVerified: false,
    galleryCount: 0,
    managementMessage: {
      message: "",
      name: "",
      position: "",
      image: "",
      id: Date.now(),
      school: 0,
    },
    curriculumBoard: [],
    gallery: [],
    perks: [],
    amenities: [],
    state: null,
    country: null,
  },
  gallery: defaultProfileItem,
  amenities: defaultProfileItem,
  perks: defaultProfileItem,
})

export default ProfileContext
