import { Fragment } from "react"

import { CircularProgress, Container } from "@suraasa/placebo-ui"
import clsx from "clsx"

import BackButton from "./BackButton"
import Navbar, { NavbarProps } from "./Navbar"

export type PageProps = {
  children: React.ReactNode
  navbarProps?: NavbarProps
  hideBanner?: boolean
  hideNavbar?: boolean
  hideContainer?: boolean
  hideBackButton?: boolean
  className?: string
  loading?: boolean
}

/**
 *  A wrapper component that provide basic page layout.
 */
const Page = ({
  children,
  navbarProps,
  hideBanner,
  hideContainer,
  hideNavbar,
  hideBackButton,
  className,
  loading,
}: PageProps) => {
  const Wrapper = hideContainer ? Fragment : Container

  return (
    <div className={clsx("pb-3", className)}>
      {!hideNavbar && <Navbar {...navbarProps} />}
      {!hideBanner && <div role="banner" />}
      <Wrapper>
        {loading ? (
          <div className="flex items-center justify-center py-6">
            <CircularProgress />
          </div>
        ) : (
          <>
            {!hideBackButton && <BackButton className="mb-2" />}
            {children}
          </>
        )}
      </Wrapper>
    </div>
  )
}

export default Page
