import { useEffect, useState } from "react"

import { Button, LinearProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

import api from "api"
import { Analytics, School } from "api/resources/school/types"
import AnalyticCard from "components/shared/AnalyticCard"
import Page from "components/shared/Page"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  card: {
    border: `1px solid ${theme.colors.surface[300]}`,
    borderRadius: "4px",
    background: theme.colors.common.white[500],
  },
}))

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [isFetchingSchool, setIsFetchingSchool] = useState(false)

  const [analytics, setAnalytics] = useState<Analytics>()
  const [school, setSchool] = useState<School | null>(null)

  const classes = useStyles()

  useEffect(() => {
    const getAnalytics = async () => {
      const res = await api.school.getDashboardAnalytics()

      if (res.isSuccessful) {
        if ("initialVerifierStats" in res.data) {
          setAnalytics(res.data.initialVerifierStats)
        }
      }
      setLoading(false)
    }
    const getSchool = async () => {
      const res = await api.school.getAssignedSchool()

      if (res.isSuccessful) {
        setSchool(res.data)
      }
      setLoading(false)
    }

    getAnalytics()
    getSchool()
  }, [])

  const fetchNewSchoolProfile = () => {
    const getSchool = async () => {
      setIsFetchingSchool(true)
      const res = await api.school.assignRandomSchool()
      if (res.isSuccessful) {
        setSchool(res.data)
      } else {
        toast.error(
          res.errors.fieldErrors?.noSchoolsAvailable
            ? "No school profiles available right now, please check back later"
            : res.errors.message ||
                "We're having trouble processing your request"
        )
      }
      setIsFetchingSchool(false)
    }

    getSchool()
  }

  return (
    <Page loading={loading} hideBackButton>
      {analytics && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mb-5">
          <AnalyticCard
            metric={analytics.allCompletedProfiles}
            timePeriod="All Time"
            title="Profiles Completed"
          />
          <AnalyticCard
            metric={analytics.monthlyCompletedProfiles}
            percentChange={analytics.performance}
            percentChangePeriod="vs. previous month"
            timePeriod={format(new Date(), "MMMM")}
            title="Monthly Profiles Completed"
          />
        </div>
      )}
      {school ? (
        <div className={clsx(classes.card, "p-6")}>
          <div className="flex gap-6 mb-3">
            <div>
              <Typography variant="strongSmallBody">
                {school.initialSchoolProfile.name}
              </Typography>
              <Typography color="onSurface.500" variant="smallBody">
                School Name
              </Typography>
            </div>
            <div>
              <Typography variant="strongSmallBody">
                <a
                  href={school.initialSchoolProfile.website}
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                  target="_blank"
                >
                  {school.initialSchoolProfile.website}
                </a>
              </Typography>
              <Typography color="onSurface.500" variant="smallBody">
                School Website
              </Typography>
            </div>
            <div className="flex flex-grow items-end flex-col">
              <Typography variant="strongSmallBody">
                {format(new Date(school.dateCreated), "do LLLL yyyy")}
              </Typography>
              <Typography color="onSurface.500" variant="smallBody">
                Date Assigned
              </Typography>
            </div>
          </div>
          <Typography
            className="mb-1"
            color="onSurface.500"
            variant="smallBody"
          >
            <b>{school.profileCompletedPercentage}%</b> Profile Completed
          </Typography>
          <LinearProgress value={school.profileCompletedPercentage} />
          <div className="mt-8 flex items-center gap-3">
            <Button
              component={Link}
              size="sm"
              to={routes.profile.replace(
                ":id",
                `${school.initialSchoolProfile.id}`
              )}
            >
              Open Profile
            </Button>
            <Button color="critical" variant="text" disabled>
              Report School
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            classes.card,
            "p-10 flex items-center justify-center flex-col"
          )}
        >
          <Typography
            className="mb-2"
            color="onSurface.500"
            textAlign="center"
            variant="smallBody"
          >
            No school assigned to you.
          </Typography>
          <Button loading={isFetchingSchool} onClick={fetchNewSchoolProfile}>
            Get New School Profile
          </Button>
          <Button
            className="mt-1"
            component={Link}
            to={routes.IV.createNewSchool}
            variant="text"
          >
            Create New School
          </Button>
        </div>
      )}
    </Page>
  )
}

export default Dashboard
