import React from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"

import api from "api"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"

const AccountDetails = ({
  onEmailUpdate,
  id,
  isVerified,
  email,
}: {
  onEmailUpdate: (newEmail: string) => void
  id: string | number
  isVerified: boolean
  email: string
}) => {
  const {
    register,
    reset,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm<{ email: string; password: string }>({
    defaultValues: {
      email,
    },
  })

  const [loading, setLoading] = React.useState(false)

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    const res = await api.profile.changeEmail({
      data: { email: data.email, password: data.password },
      urlParams: { id },
    })
    if (res.isSuccessful) {
      setLoading(false)
      toast.success("Email and password have been updated successfully")
      onEmailUpdate(data.email)
      reset({ password: "" })
    } else {
      setLoading(false)
      handleErrors(setError, res.errors)
    }
  })

  return (
    <div>
      {isVerified ? (
        <Typography
          color="onSurface.900"
          display="block"
          textAlign="center"
          variant="title3"
        >
          Account details cannot be changed because school is verified.
        </Typography>
      ) : (
        <form className="flex flex-col gap-3" onSubmit={onSubmit}>
          <TextField
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            label="School's Login Email ID"
            type="email"
            fullWidth
            {...register("email", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            label="School's Login Password"
            type="password"
            fullWidth
            {...register("password", {
              required: { value: true, message: "Required" },
            })}
          />

          <Button className="mt-2" loading={loading} type="submit">
            Save
          </Button>
        </form>
      )}
    </div>
  )
}

export default AccountDetails
