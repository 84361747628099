import React from "react"

import { Button, Typography, TypographyProps } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

import { BaseComponentProps } from "types"

const useStyles = createUseStyles(theme => ({
  toggleTruncateButton: {
    marginLeft: theme.spacing(1),
  },
  textDisplayField: {
    whiteSpace: "pre-line",
  },
}))

type TruncatedTextProp = {
  maxLength: number
} & TypographyProps

const TruncatedText = ({
  maxLength,
  children,
  className,
  ...props
}: React.PropsWithChildren<BaseComponentProps<TruncatedTextProp>>) => {
  const [seeAll, setSeeAll] = React.useState(false)

  const classes = useStyles()

  return (
    <div className={className}>
      <Typography
        display="inline"
        {...props}
        className={classes.textDisplayField}
      >
        {children &&
          (!seeAll && children.toString().length > maxLength
            ? `${children.toString().slice(0, maxLength)}...`
            : children.toString())}
      </Typography>

      {children && children.toString().length > maxLength && (
        <Button
          className={classes.toggleTruncateButton}
          variant="link"
          onClick={() => setSeeAll(!seeAll)}
        >
          {seeAll ? "Read Less" : "Read More"}
        </Button>
      )}
    </div>
  )
}

export default TruncatedText
