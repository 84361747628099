import { useEffect, useState } from "react"

import { useSearchParams } from "react-router-dom"

export type Tab = {
  name: string
  content: React.ReactElement
} & Record<any, any>
export type Tabs = Tab[]

const useTabs = ({
  initialTab,
  tabs,
  searchParamKey = "tab",
}: {
  initialTab?: string
  tabs: Tabs
  searchParamKey?: string
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(
    (searchParams.get(searchParamKey) || initialTab) ?? tabs[0].name
  )

  useEffect(() => {
    if (currentTab) {
      setSearchParams(
        {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          [searchParamKey]: currentTab,
        },
        { replace: true }
      )
    }
  }, [currentTab, searchParamKey, setSearchParams])

  return [
    tabs.find(tab => tab.name === currentTab) || tabs[0],
    setCurrentTab,
  ] as const
}

export default useTabs
