import React, { useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"

import { Cancel } from "iconoir-react"

import api from "api"
import {
  Amenity,
  Gallery as GalleryType,
  Perk,
  Profile as ProfileType,
} from "api/resources/profile/types"
import Cover from "components/profile/Cover"
import Gallery from "components/profile/Gallery"
import Overview from "components/profile/Overview"
import PerksAndAmenities from "components/profile/PerksAndAmenities"
import ReportSchool from "components/profile/ReportSchool"
import SubmitSchoolProfile from "components/profile/SubmitSchoolProfile"
import SubmitSuccessToast from "components/profile/SubmitSuccessToast"
import useArray from "utils/hooks/useArray"
import useIAM from "utils/hooks/useIAM"
import { routes } from "utils/routes"
import toast from "utils/toast"

import ProfileContext from "./context"

const useStyles = createUseStyles({
  container: {
    paddingBottom: theme.spacing(2),
  },

  dialogContent: {
    padding: 0,
  },

  dialogContainer: {
    width: "100%",
  },

  cancelButton: {
    borderRadius: "100%",
    width: "27px",
    height: "27px",
    border: `2px solid ${theme.colors.onSurface[500]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  dialog: {
    width: "100%",

    "& > div > div": {
      padding: 0,
    },

    "& > div > div > div": {
      width: "100%",

      "& > p": {
        width: "100%",
      },
    },
  },
})

const Profile = () => {
  const [profile, setProfile] = useState<ProfileType>({
    id: 0,
    schoolId: 0,
    name: "",
    website: "",
    email: "",
    branch: null,
    phoneNumber: null,
    isSalesVerified: false,
    schoolAdmin: {
      email: "",
      profile: { phoneNumber: { code: 0, number: 0 } },
    },
    address: "",
    description: "",
    logo: "",
    city: null,
    pincode: null,
    coverImage: "",
    dateEstablished: "",
    workingDays: [],
    dayStartTime: "",
    dayEndTime: "",
    teachingMode: 3,
    isVerified: false,
    managementMessage: null,
    curriculumBoard: [],
    galleryCount: 0,
    gallery: [],
    perks: [],
    amenities: [],
    state: null,
    country: null,
  })
  const [loading, setLoading] = useState(true)
  const [showSuccessProfileToast, setShowSuccessProfileToast] = useState(false)
  const [openProfileDialog] = useState(true)
  const [openSubmitProfile, setOpenSubmitProfile] = useState(false)
  const [openReportSchool, setOpenReportSchool] = useState(false)
  const { id } = useParams()
  const gallery = useArray<GalleryType>([])
  const amenities = useArray<Amenity>([])
  const perks = useArray<Perk>([])
  const classes = useStyles()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const navigate = useNavigate()
  const IS_IV = useIAM("IV")

  useEffect(() => {
    async function getProfile() {
      setLoading(true)

      const res = await api.profile.retrieve({
        params: {
          photos: 8,
        },
        urlParams: { id: id || 0 },
      })

      if (res.isSuccessful) {
        setProfile(res.data)

        if (res.data.gallery) {
          gallery.set(res.data.gallery)
        }
        if (res.data.amenities) {
          amenities.set(res.data.amenities)
        }
        if (res.data.perks) {
          perks.set(res.data.perks)
        }
        setLoading(false)
      } else {
        toast.error(res.errors.message || "Something went wrong")
        navigate(routes.home, { replace: true })
        setLoading(false)
      }
    }

    if (parseInt(`${id}`)) getProfile()
    else {
      toast.error("Invalid profile ID.")
      navigate(routes.home, { replace: true })
    }

    // @ts-ignore Will run only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <ProfileContext.Provider
      value={{
        isPublic: profile.isSalesVerified,
        profile,
        updateProfile: newState => {
          setProfile(prevState => ({
            ...prevState,
            ...newState,
          }))
        },
        gallery: {
          data: gallery.array,
          update: gallery.updateByKey,
          remove: gallery.removeByKey,
          add: gallery.push,
          set: gallery.set,
        },
        perks: {
          data: perks.array,
          update: perks.updateByKey,
          remove: perks.removeByKey,
          add: perks.push,
          set: perks.set,
        },
        amenities: {
          data: amenities.array,
          update: amenities.updateByKey,
          remove: amenities.removeByKey,
          add: amenities.push,
          set: amenities.set,
        },
      }}
    >
      <SubmitSchoolProfile
        handleClose={() => setOpenSubmitProfile(false)}
        open={openSubmitProfile}
        onSuccess={() => setShowSuccessProfileToast(true)}
      />

      <ReportSchool
        handleClose={() => setOpenReportSchool(false)}
        open={openReportSchool}
      />

      <Dialog
        animation="slide"
        className={classes.dialog}
        open={openProfileDialog}
        fullScreen
      >
        <DialogTitle>
          {IS_IV ? (
            <Container>
              <span className="flex items-center justify-between py-1.5">
                <IconButton
                  className={classes.cancelButton}
                  onClick={() => navigate(routes.home)}
                >
                  <Cancel color={theme.colors.onSurface[500]} />
                </IconButton>

                <div>
                  <Button
                    className="mr-2"
                    color="critical"
                    variant="text"
                    disabled
                    onClick={() => setOpenReportSchool(true)}
                  >
                    Report School
                  </Button>
                  <Button
                    size="sm"
                    variant="filled"
                    onClick={() => setOpenSubmitProfile(true)}
                  >
                    Submit Profile
                  </Button>
                </div>
              </span>
            </Container>
          ) : (
            <span className="flex flex-col">
              <Container className={classes.dialogContainer}>
                <IconButton
                  className={clsx("my-2", classes.cancelButton)}
                  onClick={() => navigate(routes.home)}
                >
                  <Cancel color={theme.colors.onSurface[500]} />
                </IconButton>
              </Container>
              {!profile.isSalesVerified && !loading && (
                <>
                  <Divider className="m-0" />
                  <Container className={classes.dialogContainer}>
                    <div className="flex items-center justify-between py-2 flex-wrap">
                      <div className="flex items-center gap-4">
                        <div>
                          <Typography variant="strongSmallBody">
                            +{profile.schoolAdmin.profile.phoneNumber.code}{" "}
                            {profile.schoolAdmin.profile.phoneNumber.number}
                          </Typography>
                          <Typography variant="smallBody">
                            Phone number
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            style={{ wordBreak: "break-word" }}
                            variant="strongSmallBody"
                          >
                            {profile.schoolAdmin.email || ""}
                          </Typography>
                          <Typography variant="smallBody">Email ID</Typography>
                        </div>
                      </div>
                      <div>
                        <Button
                          className="mr-2"
                          color="critical"
                          variant="text"
                          disabled
                          onClick={() => setOpenReportSchool(true)}
                        >
                          Report School
                        </Button>
                        <Button
                          size="sm"
                          variant="filled"
                          onClick={() => setOpenSubmitProfile(true)}
                        >
                          Mark as Verified
                        </Button>
                      </div>
                    </div>
                  </Container>
                </>
              )}
            </span>
          )}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div>
            {loading ? (
              <div className="flex justify-center mt-20">
                <CircularProgress />
              </div>
            ) : (
              <>
                <Cover isXs={isXs} />
                {isXs ? (
                  <div className={classes.container}>
                    <Overview />
                    <Gallery />
                    <PerksAndAmenities />
                  </div>
                ) : (
                  <Container className={classes.container}>
                    <Overview />
                    <Gallery />
                    <PerksAndAmenities />
                  </Container>
                )}
              </>
            )}

            {showSuccessProfileToast && (
              <SubmitSuccessToast
                handleClose={() => setShowSuccessProfileToast(false)}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </ProfileContext.Provider>
  )
}

export default Profile
