import { useEffect, useState } from "react"

import { theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { useNavigate, useParams } from "react-router-dom"

import api from "api"
import { Profile } from "api/resources/profile/types"
import PublicDetails from "components/profile/editProfile/PublicDetails"
import Page from "components/shared/Page"
import { routes } from "utils/routes"
import toast from "utils/toast"

function EditProfile() {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const { id } = useParams()

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  const [publicDetails, setPublicDetails] = useState<Profile>({
    id: 0,
    schoolId: 0,
    curriculumBoard: [],
    name: "",
    branch: null,
    website: "",
    isSalesVerified: false,
    email: "",
    phoneNumber: null,
    schoolAdmin: {
      email: "",
      profile: { phoneNumber: { code: 0, number: 0 } },
    },
    address: "",
    description: "",
    logo: null,
    city: null,
    pincode: null,
    coverImage: null,
    galleryCount: 0,
    dateEstablished: null,
    workingDays: [],
    dayStartTime: "00:00",
    dayEndTime: "00:00",
    teachingMode: null,
    isVerified: true,
    country: null,
    state: null,
  })

  useEffect(() => {
    async function getData() {
      setLoading(true)

      const promises = [
        api.profile.retrieve({
          params: {
            fields: ["curriculum_board"],
            photos: 8,
          },
          urlParams: { id: id || 0 },
        }),
      ] as const

      const [publicDetailsRes] = await Promise.all(promises)

      if (publicDetailsRes.isSuccessful) {
        if (publicDetailsRes.data.isSalesVerified) {
          navigate(routes.home, { replace: true })
          return
        }

        setPublicDetails(publicDetailsRes.data)
      } else {
        toast.error(publicDetailsRes.errors.message || "Something went wrong")
      }
      setLoading(false)
    }

    if (parseInt(`${id}`)) getData()
    else {
      toast.error("Invalid profile ID")
      navigate(routes.home, { replace: true })
    }

    // @ts-ignore Will run only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Page hideBackButton={isXs} loading={loading}>
      <div className={clsx("pt-8 sm:pt-0 flex flex-col gap-3")}>
        <PublicDetails
          data={publicDetails}
          id={id || 0}
          onMobileBack={() =>
            navigate(routes.profile.replace(":id", `${id || 0}`))
          }
          onUpdate={newState =>
            setPublicDetails(prevState => ({
              ...prevState,
              ...newState,
            }))
          }
        />
      </div>
    </Page>
  )
}

export default EditProfile
