import { UseFormSetError } from "react-hook-form"

import { ErrorResponse } from "api/types"

import { acceptedImageTypes } from "./constants"
import toast from "./toast"

export const isObject = (obj: unknown): obj is Record<string, unknown> =>
  Object.prototype.toString.call(obj) === "[object Object]"

export const handleErrors = <T>(
  setter: UseFormSetError<T>,
  { fieldErrors, message }: ErrorResponse["errors"]
) => {
  if (message) {
    toast.error(message)
    return
  }

  if (fieldErrors) {
    for (const [k, v] of Object.entries<unknown>(fieldErrors)) {
      /**
       * If the value for a key is an object then iterate over that object
       * and set the error for each key. This is to handle nested errors.
       */
      if (isObject(v)) {
        for (const [k1, v1] of Object.entries(v)) {
          if (v1 && typeof v1 === "string")
            setter(`${k}.${k1}` as any, { message: v1 })
        }
      } else if (v && typeof v === "string") setter(k as any, { message: v })
      else if (Array.isArray(v)) setter(k as any, { message: v[0] })
    }
  }
}

export const buildParams = (rawParams: {
  [key: string]: string | string[]
}) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(rawParams)) {
    if (!value) continue

    if (Array.isArray(value)) {
      value.forEach(v => params.append(key, v))
    } else params.append(key, value)
  }

  return params
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export function toDateTimeLocal(
  iso: string,
  { omitSeconds } = { omitSeconds: false }
) {
  const date = new Date(iso)

  const YYYY = date.getFullYear()
  const MM = (date.getMonth() + 1).toString().padStart(2, "0")
  const DD = date.getDate().toString().padStart(2, "0")
  const HH = date.getHours().toString().padStart(2, "0")
  const II = date.getMinutes().toString().padStart(2, "0")
  const SS = date.getSeconds().toString().padStart(2, "0")
  if (omitSeconds) {
    return `${YYYY}-${MM}-${DD}T${HH}:${II}`
  }
  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`
}
export type ValueOf<T> = T[keyof T]

export const validateImageUpload = (file: File, imageSizeinMB?: number) => {
  if (!file.type.includes("image")) {
    toast.error("Only images are allowed")
    return
  }
  if (file.size / 1024 / 1024 > (imageSizeinMB || 5)) {
    toast.error(`Only images under ${imageSizeinMB || 5} MB are allowed`)
    return
  }

  if (!acceptedImageTypes.includes(file.type)) {
    toast.error(
      "Invalid Image Type. Only jpg, jpeg, png & webp format are allowed"
    )
    return
  }

  return file
}

export const formatWorkDuration = (days: number) => {
  if (days < 365) {
    const months = Math.floor(days / 12)
    return pluralize("month", months)
  }

  const eightMonthsInYears = 8 / 12

  const years = days / 365
  const threshold = Math.trunc(years) + eightMonthsInYears

  if (years > threshold) return `${Math.trunc(years)}+ years`

  return `${pluralize("year", Math.floor(years))}`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export const getLearnURL = (url: string) =>
  `${process.env.REACT_APP_LEARN_PLATFORM_URL}${url}`

export const formatSchoolName = (name: string, branch: string | null) => {
  if (branch) return `${name} (${branch})`

  return name
}
