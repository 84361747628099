import { useContext } from "react"

import { GlobalContext } from "GlobalState"

import { IV_ROLE_ACTION, SR_ROLE_ACTION } from "utils/config"

export type UseIAM = typeof useIAM

export default function useIAM(value: "IV" | "SR") {
  const { actions } = useContext(GlobalContext)

  if (value === "IV") return actions.data.includes(IV_ROLE_ACTION)

  if (value === "SR") return actions.data.includes(SR_ROLE_ACTION)

  return false
}
