import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  school: {
    listAccessibleSchools: () => getNebulaURL("/v1/schools/access/"),
  },
  verification: {
    schools: () =>
      getNebulaURL("/v1/school-verification/initial-verifier/schools/"),
    createSchool: () => "/jobs/schools/",
  },
  dashboardAnalytics: () => getNebulaURL("/v1/school-verification/overview/"),

  salesRep: {
    profile: () => `/jobs/school-verification/sales-rep/schools/`,
    semiVerified: () =>
      getNebulaURL("/v1/school-verification/sales-rep/semi-verified-schools/"),
    base: id =>
      getNebulaURL(`/v1/school-verification/sales-rep/schools/${id}/`),
  },
})
