import React, { createContext, useState } from "react"

import { Action } from "api/resources/users/types"

export type GlobalContextType = {
  actions: {
    data: Action[]
    set: React.Dispatch<React.SetStateAction<Action[]>>
  }
}
const defaultValues = {
  actions: { data: [], set: () => {} },
}
export const GlobalContext = createContext<GlobalContextType>(defaultValues)

const GlobalState = ({ children }: { children: React.ReactNode }) => {
  const [actions, setActions] = useState<Action[]>(defaultValues.actions.data)

  return (
    <GlobalContext.Provider
      value={{
        actions: {
          data: actions,
          set: setActions,
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalState
