import global from "./resources/global"
import profile from "./resources/profile"
import school from "./resources/school"
import users from "./resources/users"

export default {
  users,
  profile,
  school,
  global,
}
