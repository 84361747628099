import { TeachingMode } from "api/resources/profile/types"

export const PLATFORM = "School"

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"
export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
  schoolId: "schoolId",
}

export const PRODUCT = {
  learning: 1,
  school: 2,
} as const
export const USER_TYPE = "School Verification Portal"

export enum ServicePrefix {
  solis = "solis",
  gravity = "gravity",
  nebula = "nebula",
}

export enum LanguageProficiency {
  ELEMENTARY = 1,
  LIMITED_WORKING,
  PROFESSIONAL_WORKING,
  FULL_PROFESSIONAL,
  NATIVE,
}

export const acceptedImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
]
/**
 * Values are in minutes
 */
export const DURATION_HOURS = [
  { label: "0 hours", value: 0 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 2 * 60 },
  { label: "3 hours", value: 3 * 60 },
]

export const DURATION_MINUTES = [
  { label: "0 minutes", value: 0 },
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
]

export const teachingModeChoices = [
  { label: "Online", value: TeachingMode.ONLINE },
  {
    label: "Offline",
    value: TeachingMode.OFFLINE,
  },
  {
    label: "Blended (Online & Offline)",
    value: TeachingMode.HYBRID,
  },
]

export const weekDays = [
  { id: 7, day: "Sunday" },
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
]

export enum ProfileNavTabs {
  publicDetails = "Public Details",
  accountDetails = "Account Details",
}
export const DEFAULT_PROFILE_COVER = "/assets/default-cover.png"
export enum JobStatus {
  UNPUBLISHED = 1,
  PUBLISHED = 2,
}

export const tabsProfile = {
  overview: "Overview",
  gallery: "Gallery",
  perksAndAmenities: "Perks and Amenities",
}

export const assessmentDurationOptions = [
  { label: "5 minutes", value: 5 * 60 },
  { label: "10 minutes", value: 10 * 60 },
  { label: "15 minutes", value: 15 * 60 },
  { label: "20 minutes", value: 20 * 60 },
  { label: "25 minutes", value: 25 * 60 },
  { label: "30 minutes", value: 30 * 60 },
  { label: "35 minutes", value: 35 * 60 },
  { label: "40 minutes", value: 40 * 60 },
  { label: "45 minutes", value: 45 * 60 },
  { label: "50 minutes", value: 50 * 60 },
  { label: "55 minutes", value: 55 * 60 },
  { label: "60 minutes", value: 60 * 60 },
]

export const PERK_CHAR_LIMIT = 50
export const AMENITIES_CHAR_LIMIT = 50
export const DEFAULT_LOGO = "/assets/schoolLogo.svg"
export const DEFAULT_COVER = "/assets/defaultCover.png"
