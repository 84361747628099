import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { WarningCircledOutline } from "iconoir-react"

import { WorkingDays as WorkingDaysType } from "api/resources/profile/types"
import { weekDays } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  roundedDay: {
    borderRadius: "50px",
    width: "35px",
    padding: "0",
    display: "grid",
    placeItems: "center",
    height: "35px",
    color: theme.colors.onSurface[500],
    background: theme.colors.surface[200],
  },

  selectedDay: {
    color: "white",
    background: theme.colors.primary[500],
  },

  error: {
    "& > svg": {
      height: 18,
      width: 18,
      flexShrink: 0,
      marginRight: theme.spacing(0.5),
      color: theme.colors.surface[500],
      fill: theme.colors.critical[500],
    },
  },
}))

type Props = {
  workingDays?: WorkingDaysType[]
  error?: boolean
  helperText?: string | string[]
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>
function WorkingDays({ workingDays, helperText, error, ...props }: Props) {
  const classes = useStyles()

  return (
    <>
      <div className="flex items-center gap-1">
        {weekDays.map(item => (
          <button
            className={clsx(classes.roundedDay, {
              [classes.selectedDay]: (workingDays ?? []).includes(item.id),
            })}
            key={item.id}
            type="button"
            value={item.id}
            {...props}
          >
            {item.day.split("")[0]}
          </button>
        ))}
      </div>
      {error && (
        <div className={clsx(classes.error, "flex items-center mt-0.5")}>
          <WarningCircledOutline />
          <Typography color="critical.500" variant="smallBody">
            {helperText}
          </Typography>
        </div>
      )}
    </>
  )
}

export default WorkingDays
