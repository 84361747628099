import { useContext, useEffect, useState } from "react"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import capitalize from "lodash/capitalize"
import { createUseStyles } from "react-jss"

import { Plus, Trash } from "iconoir-react"

import api from "api"
import RemoveDialog from "components/RemoveDialog"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

import AddDialog from "./AddDialog"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addPerks: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
  perksContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
}))

const PerksDialog = ({
  open,
  handleClose,
}: { handleClose: () => void } & Pick<DialogProps, "open">) => {
  const classes = useStyles()

  const {
    perks: { data, add, remove },
    profile: { id },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [openAddPerksDialog, setOpenAddPerksDialog] = useState(hasNoItems)
  const [perkToDelete, setPerkToDelete] = useState<string | null>(null)

  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const handleRemove = async () => {
    if (!perkToDelete) return

    setRemoveDialogLoading(true)

    const res = await api.profile.perks.delete({
      urlParams: { id, perkId: perkToDelete },
    })
    if (res.isSuccessful) {
      remove(perkToDelete)
      setPerkToDelete(null)
      toast.success("Removed successfully.")
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setRemoveDialogLoading(false)
  }

  useEffect(() => {
    if (open) setOpenAddPerksDialog(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => setOpenAddPerksDialog(false)}
      onRequestClose={() => handleClose()}
    >
      {openAddPerksDialog ? (
        <AddDialog
          handleBack={
            hasNoItems ? undefined : () => setOpenAddPerksDialog(false)
          }
          open={openAddPerksDialog}
          onAdd={add}
          onRequestClose={() => handleClose()}
        />
      ) : (
        <>
          <RemoveDialog
            handleClose={() => setPerkToDelete(null)}
            loading={removeDialogLoading}
            open={Boolean(perkToDelete)}
            title="Remove Perk"
            keepScrollLocked
            onRemove={handleRemove}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>
                {capitalize(data.find(item => item.id === perkToDelete)?.name)}
              </b>{" "}
              from the perks?
            </Typography>
          </RemoveDialog>

          <DialogTitle>Perks for Teachers</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={clsx(classes.addPerks, "py-2 px-1.5")}>
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddPerksDialog(true)}
              >
                Add New Perk
              </Button>
            </div>
            <div className="py-1.5 pr-0 pl-3 flex flex-col gap-1.5">
              {data.map(item => (
                <div className={classes.perksContainer} key={item.id}>
                  <div>
                    <Typography
                      className="pb-0.5"
                      color="onSurface.800"
                      variant="strong"
                    >
                      {capitalize(item.name)}
                    </Typography>
                  </div>
                  <IconButton
                    color="critical"
                    onClick={() => {
                      setPerkToDelete(item.id)
                    }}
                  >
                    <Trash />
                  </IconButton>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogFooter
            actions={{
              primary: null,
              secondary: {
                variant: "outlined",
                color: "secondary",
                label: "Close",
              },
            }}
          />
        </>
      )}
    </Dialog>
  )
}

export default PerksDialog
