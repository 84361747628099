import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import capitalize from "lodash/capitalize"
import { createUseStyles } from "react-jss"

import { Check } from "iconoir-react"

const useStyles = createUseStyles(theme => ({
  checkBoxContainer: {
    background: theme.colors.primary[50],
    width: "20px",
    height: "20px",
    borderRadius: "4px",
    color: theme.colors.primary[500],
    flexShrink: 0,
  },
  tileContainer: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
}))

function CheckTile({ label }: { label: string }) {
  const classes = useStyles()
  return (
    <div className={clsx("flex items-start gap-1 mb-2", classes.tileContainer)}>
      <div className={classes.checkBoxContainer}>
        <Check height="auto" width="auto" />
      </div>
      <Typography variant="body">{capitalize(label)}</Typography>
    </div>
  )
}

export default CheckTile
