export const routes = {
  home: "/",
  dashboard: "/dashboard",
  editProfile: "/profile/edit/:id",
  profile: "/profile/:id",
  sso: "/sso",

  SR: {
    home: "/sales",
    assigned: "/sales/assigned",
    getNewProfile: "/sales/get-new-profile",
  },
  IV: {
    home: "/verifier",
    getNewProfile: "/verifier/get-new-profile",
    createNewSchool: "/verifier/create-new-school",
  },
} as const
