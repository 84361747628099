import React, { useEffect, useState } from "react"

import {
  Container,
  IconButton,
  LinearProgress,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

import { Cancel, Check } from "iconoir-react"

const useStyles = createUseStyles(({ colors, spacing }) => ({
  root: { position: "fixed", width: "100%", top: "90vh" },
  container: {
    background: colors.success[50],
    borderRadius: spacing(0.5),

    width: "calc(100% - 16px)",
  },
}))

const INIT_VALUE = 100 // in percentage
const DURATION = 10 // in seconds

function SubmitSuccessToast({ handleClose }: { handleClose: () => void }) {
  const classes = useStyles()

  const [value, setValue] = useState(INIT_VALUE)

  useEffect(() => {
    // For debugging purposes
    // console.time("Completed in: ")
    const interval = setInterval(() => {
      setValue(prevState => {
        let newValue = prevState
        if (prevState <= 0) {
          // console.timeEnd("Completed in: ")
          clearInterval(interval)
        }
        newValue = prevState - 10 / INIT_VALUE

        return newValue
      })
    }, DURATION)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (value <= 0) {
      handleClose()
    }
  }, [value, handleClose])

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.container}>
          <div className="flex items-center justify-between px-2 pt-1 pb-0.75">
            <div className="flex items-center gap-2">
              <Check color={theme.colors.success[700]} />
              <Typography color="success.700">
                Profile Submitted Successfully!
              </Typography>
            </div>
            <IconButton color="success" onClick={() => handleClose()}>
              <Cancel />
            </IconButton>
          </div>
          <LinearProgress color="success" value={value} />
        </div>
      </Container>
    </div>
  )
}

export default SubmitSuccessToast
