import React, { useContext, useState } from "react"

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"

import ProfileContext from "views/Profile/context"

const rejectionReasons = [
  { key: "0", display: "Someone is impersonating to be this school" },
  { key: "1", display: "This school does not exist" },
  { key: "2", display: "This school already exists on Suraasa" },
  { key: "3", display: "Communication skills not up to the mark" },
  { key: "4", display: "Spam" },
  { key: "5", display: "Claim ownership" },
  { key: "6", display: "Other Reasons" },
] as const

type Form = {
  "0": boolean
  "1": boolean
  "2": boolean
  "3": boolean
  "4": boolean
  "5": boolean
  "6": boolean
  feedback: string
}

function ReportSchool({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) {
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, watch } = useForm<Form>()
  const otherReasons = watch("6")
  const { profile } = useContext(ProfileContext)

  const onSubmit = handleSubmit(formData => {
    console.log(formData)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      handleClose()
    }, 3000)
  })

  return (
    <Dialog open={open} onRequestClose={handleClose}>
      <DialogTitle>Report School</DialogTitle>
      <DialogContent>
        <Typography className="mb-3 mt-0.5 " variant="strong">
          Reason for reporting {profile.name}
        </Typography>
        <form className="flex gap-2 flex-col" onSubmit={onSubmit}>
          {rejectionReasons.map(({ key, display }) => (
            <Checkbox {...register(key)} key={key} label={display} />
          ))}
          <TextField
            className="mb-1.5"
            disabled={!otherReasons}
            rows={2}
            fullWidth
            multiLine
            {...register("feedback")}
            placeholder="Explain why you are reporting this school."
          />
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Report School",
            type: "submit",
            color: "critical",
            loading,
            onClick: onSubmit,
          },
          secondary: {
            label: "Cancel",
            type: "submit",
            onClick: handleClose,
          },
        }}
      />
    </Dialog>
  )
}

export default ReportSchool
