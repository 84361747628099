import React from "react"

import { Navigate, Outlet } from "react-router-dom"

import ErrorScreen from "components/ErrorScreen"
import useIAM from "utils/hooks/useIAM"
import { routes } from "utils/routes"

const CheckAccess = ({
  platformRole: role,
}: {
  /**
   * Can't write `role` because it is reserved
   */
  platformRole: "IV" | "SR"
}) => {
  const IS_IV = useIAM("IV")
  const IS_SR = useIAM("SR")

  if (IS_IV) {
    if (role === "IV") {
      return <Outlet />
    }

    return <Navigate to={routes.IV.home} replace />
  }

  if (IS_SR) {
    if (role === "SR") {
      return <Outlet />
    }

    return <Navigate to={routes.SR.home} replace />
  }

  return (
    <ErrorScreen error="Looks like you don't have permission to access this portal" />
  )
}

export default CheckAccess
