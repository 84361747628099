const ACTIONS = {
  reportSchool: "RPR001",
  viewSalesSchoolProfile: "SCP001",
  fillSalesSchoolProfile: "SCP002",
  chooseRandomUnverifiedSchool: "SCV001",
  submitUnverifiedSchoolProfile: "SCV002",
  listUnassignedSemiVerifiedSchools: "SCV003",
  chooseSemiVerifiedSchool: "SCV004",
  submitSemiVerifiedSchoolProfile: "SCV006",
  listAssignedSemiVerifiedSchoolProfiles: "SCV007",
  listAssignedVerifiedSchoolProfiles: "SCV008",
  acceptSalesSchoolProfile: "SCV009",
  createNewSchool: "SCH001",
  createSchoolUser: "USR001",
} as const

export { ACTIONS }
