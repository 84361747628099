import { useEffect, useState } from "react"

import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"

import api from "api"
import { Analytics } from "api/resources/school/types"
import AnalyticCard from "components/shared/AnalyticCard"
import Page from "components/shared/Page"
import PendingProfilesTab from "components/SR/PendingProfilesTab"
import VerifiedProfilesTab from "components/SR/VerifiedProfilesTab"
import Tabs from "components/tabs/Tabs"
import useTabs, { Tab as TabType } from "utils/hooks/useTabs"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  card: {
    border: `1px solid ${theme.colors.surface[300]}`,
    borderRadius: "4px 4px 0 0",
    background: theme.colors.common.white[500],
    overflowX: "auto",
  },
  content: {
    minWidth: "800px",
  },
}))

enum TABS {
  pendingProfile = "Pending Profiles",
  verifiedProfile = "Verified Profiles",
}

const initialTabs: TabType[] = [
  {
    name: TABS.pendingProfile,
    content: <Typography>Pending Profiles</Typography>,
  },
  {
    name: TABS.verifiedProfile,
    content: <Typography>Verified Profiles</Typography>,
  },
]

const SRDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [isFetchingData] = useState(false)
  const [allTabs] = useState(initialTabs)
  const [currentTab, setCurrentTab] = useTabs({ tabs: allTabs })
  const [renderedTab, setRenderedTab] = useState<JSX.Element>()

  const [analytics, setAnalytics] = useState<Analytics>()
  const navigate = useNavigate()

  const classes = useStyles()

  useEffect(() => {
    const fetchAnalytics = async () => {
      setLoading(true)
      const res = await api.school.getDashboardAnalytics()

      if (res.isSuccessful) {
        if ("salesRepStats" in res.data) {
          setAnalytics(res.data.salesRepStats)
        }
      } else {
        toast.error(res.errors.message || "Something went wrong")
      }
    }
    fetchAnalytics()
    setLoading(false)
  }, [])

  useEffect(() => {
    switch (currentTab.name) {
      case TABS.pendingProfile:
        setRenderedTab(<PendingProfilesTab />)
        break
      case TABS.verifiedProfile:
        setRenderedTab(<VerifiedProfilesTab />)
        break
      default:
    }
  }, [currentTab])

  return (
    <Page loading={loading} hideBackButton>
      <div
        className={clsx(
          {
            "grid grid-cols-1 sm:grid-cols-3 gap-2": Boolean(analytics),
          },
          "mb-5"
        )}
      >
        {analytics && (
          <>
            <AnalyticCard
              metric={analytics.allCompletedProfiles}
              timePeriod="All Time"
              title="Profiles verified"
            />
            <AnalyticCard
              metric={analytics.monthlyCompletedProfiles}
              percentChange={
                analytics.performance !== null ? analytics.performance : 0
              }
              percentChangePeriod="vs. previous month"
              timePeriod="December"
              title="Monthly Profiles Verified"
            />
          </>
        )}
        <div className="flex justify-end items-start">
          <Button onClick={() => navigate(routes.SR.getNewProfile)}>
            Get New Profile
          </Button>
        </div>
      </div>
      <div className={clsx(classes.card)}>
        <div className={classes.content}>
          <Tabs
            activeTab={currentTab}
            className="px-3 pt-2 pb-1"
            tabs={allTabs}
            onChange={setCurrentTab}
          />
          {isFetchingData ? (
            <div className="p-4 flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            renderedTab
          )}
        </div>
      </div>
    </Page>
  )
}

export default SRDashboard
