import { useEffect, useState } from "react"

import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

import api from "api"
import { AssignedSchool } from "api/resources/school/types"
import SchoolLogo from "components/shared/SchoolLogo"
import { formatSchoolName } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  item: {
    display: "grid",
    gridTemplateColumns: "34px 1.25fr 1fr 1fr",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  borderTop: {
    borderTop: `1px solid ${theme.colors.surface[300]}`,
  },
  imageContainer: {
    width: "34px",
    height: "34px",
    objectFit: "contain",
  },
}))

const VerifiedProfilesTab = () => {
  const classes = useStyles()
  const [schools, setSchools] = useState<AssignedSchool[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getSchools = async () => {
      const res = await api.school.salesRep.getSchoolProfiles({
        params: {
          verification_completed: true,
        },
      })

      if (res.isSuccessful) {
        setSchools(res.data)
      } else {
        toast.error(
          res.errors.message || "We're unable to load the list of schools"
        )
      }
      setLoading(false)
    }
    getSchools()
  }, [])

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center py-10 px-10">
          <CircularProgress />
        </div>
      ) : (
        <>
          {schools.length > 0 ? (
            <div>
              {schools.map((school, index) => (
                <div
                  className={clsx(classes.item, classes.borderTop, "px-3 py-2")}
                  key={index}
                >
                  <SchoolLogo
                    className={classes.imageContainer}
                    src={school.logo}
                  />
                  <div className="flex flex-col">
                    <Typography variant="strong">
                      {formatSchoolName(school.name, school.branch)}
                    </Typography>
                    {school.state && (
                      <Typography color="onSurface.500">
                        {school.state.name}, {school.country?.name}
                      </Typography>
                    )}
                  </div>
                  <div />
                  <div className="flex justify-end">
                    <Button
                      component={Link}
                      to={routes.profile.replace(":id", `${school.id}`)}
                      variant="text"
                    >
                      View Profile
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={clsx(
                classes.borderTop,
                "p-4 flex items-center justify-center"
              )}
            >
              <Typography>No verified profiles found.</Typography>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default VerifiedProfilesTab
