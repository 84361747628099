import { useState } from "react"

import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Select,
  TextField,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import cover from "assets/school-background.png"
import clsx from "clsx"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import isURL from "validator/es/lib/isURL"

import { Cancel } from "iconoir-react"

import api from "api"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(() => ({
  fixedWidth: {
    width: "360px",
  },
  divider: {
    maxWidth: "636px",
    width: "100%",
    margin: theme.spacing(3, 0),
  },
  countryCode: {
    maxWidth: "120px",
  },
  phoneNumberField: {
    maxWidth: "300px",
  },
  cancelButton: {
    borderRadius: "100%",
    width: "27px",
    height: "27px",
    border: `2px solid ${theme.colors.onSurface[500]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: 0,
  },
  coverContainer: {
    position: "relative",
  },
  coverImage: {
    width: "100%",
    minHeight: "100px",
  },
  coverText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dialog: {
    width: "100%",

    "& .DialogTitle-container": {
      padding: 0,
    },

    // TODO: placebo-issue add slot for dialogTitle
    "& .DialogTitle-container > div": {
      width: "100%",

      "& > p": {
        width: "100%",
      },
    },
  },
}))

type Form = {
  school: {
    name: string
    website: string
  }
  user: {
    email: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    phoneNumber: string | null
    countryCode: string | null
  }
}

const CreateSchool = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [openCreateSchoolDialog] = useState(true)
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      user: {
        phoneNumber: "",
        countryCode: "",
      },
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (data.user.password !== data.user.confirmPassword) {
      setError("user.confirmPassword", { message: "Passwords do not match" })
      return
    }

    const res = await api.school.createNewSchool({
      data: {
        user: {
          email: data.user.email,
          password: data.user.password,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          phoneNumber: `${data.user.countryCode}${data.user.phoneNumber}`,
        },
        school: {
          name: data.school.name,
          website: data.school.website,
        },
      },
    })

    if (res.isSuccessful) {
      toast.success("School created successfully")
      navigate(routes.home)
    } else {
      handleErrors(setError, res.errors)
    }
  })

  return (
    <Dialog
      animation="slide"
      className={classes.dialog}
      open={openCreateSchoolDialog}
      fullScreen
    >
      <DialogTitle>
        <Container>
          <span className="flex items-center justify-between py-1.5">
            <IconButton
              className={classes.cancelButton}
              onClick={() => navigate(routes.home)}
            >
              <Cancel color={theme.colors.onSurface[500]} />
            </IconButton>
          </span>
        </Container>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.coverContainer}>
          <img alt="" className={classes.coverImage} src={cover} />

          <Typography
            className={classes.coverText}
            color="common.white.500"
            textAlign="center"
            variant="title1"
          >
            Create New School
          </Typography>
        </div>
        <Container className="mt-8  mb-4">
          <form onSubmit={onSubmit}>
            <div className="flex items-center flex-col gap-3">
              <div className={clsx(classes.fixedWidth, "flex flex-col gap-3")}>
                <TextField
                  error={Boolean(errors.school?.name)}
                  helperText={errors.school?.name?.message}
                  label="School Name"
                  fullWidth
                  {...register("school.name", {
                    required: { value: true, message: "Required" },
                  })}
                />
                <TextField
                  error={Boolean(errors.school?.website)}
                  helperText={errors.school?.website?.message}
                  label="School Website"
                  fullWidth
                  {...register("school.website", {
                    validate: value =>
                      isURL(value, {
                        protocols: ["https", "http"],
                      }) || "Invalid URL",
                    required: { value: true, message: "Required" },
                  })}
                />
              </div>

              <Divider className={classes.divider} />

              <div className={clsx(classes.fixedWidth, "flex flex-col gap-3")}>
                <TextField
                  error={Boolean(errors.user?.firstName)}
                  helperText={errors.user?.firstName?.message}
                  label="First Name"
                  fullWidth
                  {...register("user.firstName", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <TextField
                  error={Boolean(errors.user?.lastName)}
                  helperText={errors.user?.lastName?.message}
                  label="Last Name"
                  fullWidth
                  {...register("user.lastName", {
                    required: { value: false, message: "Required" },
                  })}
                />

                <div>
                  <div className="flex gap-2">
                    <Controller
                      control={control}
                      name="user.countryCode"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          className={classes.countryCode}
                          error={Boolean(errors.user?.countryCode)}
                          getOptionLabel={({ dialCode }) => dialCode}
                          getOptionValue={({ dialCode }) => dialCode}
                          helperText={errors.user?.countryCode?.message}
                          label="Code"
                          options={countryCodes}
                          placeholder="+91"
                          value={
                            value
                              ? countryCodes.find(
                                  item => item.dialCode === value
                                )
                              : null
                          }
                          fullWidth
                          onBlur={onBlur}
                          onChange={val => {
                            onChange(val?.dialCode)
                          }}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Required" },
                      }}
                    />

                    <TextField
                      className={classes.phoneNumberField}
                      error={Boolean(errors.user?.phoneNumber)}
                      helperText={errors.user?.phoneNumber?.message}
                      label="Phone Number"
                      placeholder="9845XX XXXXX"
                      type="tel"
                      fullWidth
                      {...register("user.phoneNumber", {
                        required: { value: true, message: "Required" },
                        maxLength: {
                          value: 12,
                          message: "Length cannot be greater than 12",
                        },
                        minLength: {
                          value: 8,
                          message: "Length cannot be less than 8",
                        },
                      })}
                    />
                  </div>

                  <Typography
                    className="mt-1"
                    color="onSurface.500"
                    variant="smallBody"
                  >
                    This phone number will be used to verify school’s identity.
                    Make sure they have access to this number.
                  </Typography>
                </div>
              </div>

              <Divider className={classes.divider} />

              <div className={clsx(classes.fixedWidth, "flex flex-col gap-3")}>
                <Typography color="onSurface.500" variant="smallBody">
                  This email and password will be used to login in to their
                  Suraasa Account.
                </Typography>

                <TextField
                  error={Boolean(errors.user?.email)}
                  helperText={errors.user?.email?.message}
                  label="Email Address"
                  placeholder="johndoe@example.com"
                  type="email"
                  fullWidth
                  {...register("user.email", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <TextField
                  error={Boolean(errors.user?.password)}
                  helperText={errors.user?.password?.message}
                  label="Password"
                  type="password"
                  fullWidth
                  {...register("user.password", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <TextField
                  error={Boolean(errors.user?.confirmPassword)}
                  helperText={errors.user?.confirmPassword?.message}
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  {...register("user.confirmPassword", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <Button type="submit" fullWidth>
                  Create New School
                </Button>
              </div>
            </div>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default CreateSchool
