import React, { useContext, useState } from "react"

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { useNavigate } from "react-router-dom"

import api from "api"
import useIAM from "utils/hooks/useIAM"
import { routes } from "utils/routes"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

function SubmitSchoolProfile({
  open,
  onSuccess,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
  onSuccess: () => void
}) {
  const [primaryLoading, setPrimaryLoading] = useState(false)
  const [secondaryLoading, setSecondaryLoading] = useState(false)

  const navigate = useNavigate()

  const {
    profile: { schoolId, name },
  } = useContext(ProfileContext)

  const IS_IV = useIAM("IV")

  const getNewSchoolProfile = async () => {
    const res = await api.school.assignRandomSchool()
    if (res.isSuccessful) {
      navigate(
        routes.profile.replace(":id", `${res.data.initialSchoolProfile.id}`),
        { replace: true }
      )
    } else {
      toast.error(
        res.errors.fieldErrors?.noSchoolsAvailable
          ? "No school profiles available right now, please check back later"
          : res.errors.message || "We're having trouble processing your request"
      )
      navigate(routes.home, { replace: true })
    }
  }

  const submitProfile = async () => {
    setSecondaryLoading(true)
    const res = await api.profile.submit({
      urlParams: { id: schoolId },
    })
    if (res.isSuccessful) {
      toast.success("Profile submitted successfully")
      navigate(routes.home, { replace: true })
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setSecondaryLoading(false)
  }

  const submitAndGetProfile = async () => {
    setPrimaryLoading(true)
    const res = await api.profile.submit({
      urlParams: { id: schoolId },
    })
    if (res.isSuccessful) {
      toast.success("Profile submitted successfully")
      getNewSchoolProfile()
      onSuccess()
      handleClose()
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setPrimaryLoading(false)
  }

  const markAsVerified = async () => {
    setPrimaryLoading(true)
    const res = await api.school.salesRep.submitProfile({
      urlParams: { id: schoolId },
    })
    if (res.isSuccessful) {
      toast.success("Profile submitted successfully")
      navigate(routes.home)
      handleClose()
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setPrimaryLoading(false)
  }

  return (
    <div>
      <Dialog open={open} onRequestClose={handleClose}>
        <DialogTitle>
          {IS_IV ? "Submit School Profile" : "Mark school as verified"}
        </DialogTitle>
        <DialogContent>
          <Typography className="mb-7" variant="smallBody">
            {IS_IV ? (
              "Are you sure you want to submit this school profile for verification?"
            ) : (
              <>
                Are you sure you want to mark <b>{name}</b> as verified?
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: IS_IV ? "Submit & Get New Profile" : "Mark as Verified",
              type: "button",
              onClick: IS_IV ? submitAndGetProfile : markAsVerified,
              loading: primaryLoading,
            },
            secondary: {
              label: IS_IV ? "Submit" : "Cancel",
              type: "button",
              loading: secondaryLoading,
              onClick: IS_IV ? submitProfile : handleClose,
            },
          }}
        />
      </Dialog>
    </div>
  )
}

export default SubmitSchoolProfile
