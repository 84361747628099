import React, { useCallback, useEffect, useState } from "react"

import { Button, Pagination, Table, Typography } from "@suraasa/placebo-ui"
import { format } from "date-fns"
import snakeCase from "lodash/snakeCase"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"

import { ArrowUp } from "iconoir-react"

import api from "api"
import { SalesSchoolProfile } from "api/resources/school/types"
import { PaginatedResponse } from "api/types"
import Page from "components/shared/Page"
import SchoolLogo from "components/shared/SchoolLogo"
import { formatSchoolName } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

import SearchField from "./SearchField"

const useStyles = createUseStyles(theme => ({
  table: {
    border: `1px solid ${theme.colors.onSurface[200]}`,
    background: theme.colors.common.white[500],

    "& tr > td:first-child": {
      paddingLeft: theme.spacing(2.5),
    },
    "& tr > td:last-child": {
      paddingRight: theme.spacing(3.5),
    },
    "& tr:not(:last-child), thead": {
      borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    },
    "& td, th": {
      padding: theme.spacing(2, 1),
    },

    "& .school-logo-col": {
      width: "34px",
    },
  },
  imageContainer: { width: "34px", height: "34px", objectFit: "contain" },
}))

function GetNewProfile() {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [assignLoading, setAssignLoading] = useState(0)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [sortColumns, setSortColumns] = useState<string[]>([])

  const [schoolProfiles, setSchoolProfiles] = useState<
    PaginatedResponse<SalesSchoolProfile[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const assignProfile = async (id: number, schoolId: number) => {
    setAssignLoading(id)
    const res = await api.school.salesRep.assignProfile({ urlParams: { id } })
    if (res.isSuccessful) {
      navigate(routes.profile.replace(":id", `${schoolId}`))
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setAssignLoading(0)
  }

  const getSchools = useCallback(
    async (search = "", options?: { resetPage: boolean }) => {
      const pageNumber = options?.resetPage ? 1 : page
      setLoading(true)
      const res = await api.school.salesRep.getSemiVerifiedProfiles({
        params: {
          page: pageNumber,
          search,
          ordering: sortColumns,
        },
      })

      if (res.isSuccessful) {
        setSchoolProfiles(res.data)
      } else {
        toast.error(res.errors.message || "Something went wrong")
      }
      setLoading(false)
    },
    [page, sortColumns]
  )

  useEffect(() => {
    getSchools()
  }, [page, getSchools])

  return (
    <Page>
      <div className="flex flex-wrap justify-between items-center mt-1 mb-2 gap-1">
        <Typography variant="title3">Get New Profile</Typography>

        <SearchField
          onSearch={q => {
            getSchools(q, { resetPage: true })
          }}
        />
      </div>
      <Table
        className={classes.table}
        data={schoolProfiles.data}
        headers={[
          { id: "logo" },
          { id: "name", title: "Name", sortable: true },
          { id: "dateCreated", title: "Signed Up On", sortable: true },
          { id: "action", title: "", sortable: false },
        ]}
        loading={loading}
        rowComponent={row => (
          <tr>
            <td className="school-logo-col">
              <div className="flex justify-center">
                <SchoolLogo
                  className={classes.imageContainer}
                  src={row.initialSchoolProfile.logo}
                />
              </div>
            </td>
            <td>
              <div className="flex flex-col">
                <Typography variant="strong">
                  {formatSchoolName(
                    row.initialSchoolProfile.name,
                    row.initialSchoolProfile.branch
                  )}
                </Typography>
                <Typography color="onSurface.500">
                  {row.initialSchoolProfile.state?.name &&
                    `${row.initialSchoolProfile.state.name}, `}
                  {row.initialSchoolProfile.country?.name}
                </Typography>
              </div>
            </td>
            <td>
              {row.dateCreated && (
                <div className="flex items-center gap-0.5">
                  <Typography variant="strong">
                    {format(new Date(row.dateCreated), "dd MMM yyyy")},
                  </Typography>
                  <Typography color="onSurface.500" variant="body">
                    {format(new Date(row.dateCreated), "hh:mm a")}
                  </Typography>
                </div>
              )}
            </td>
            <td>
              <div className="flex justify-end items-center">
                <Button
                  loading={assignLoading === row.id}
                  variant="text"
                  onClick={() =>
                    assignProfile(row.id, row.initialSchoolProfile.id)
                  }
                >
                  Assign
                </Button>
              </div>
            </td>
          </tr>
        )}
        sortIcon={<ArrowUp />}
        onSort={columns => {
          setSortColumns(
            columns.map(c => `${c.startsWith("-") ? "-" : ""}${snakeCase(c)}`)
          )
        }}
      />

      <Pagination page={page} total={schoolProfiles.total} onChange={setPage} />
    </Page>
  )
}

export default GetNewProfile
