/**
 * Problem: Need to show notifications from outside of a react component. By default, we cannot use Toastbar outside of react component.
 * Solution: Create a component that returns null and use refs to call the Toastbar outside of a react component.
 * Reference: https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
 */

import { ProviderContext } from "./ToastContext"
import { Toast, ToastOptions } from "./types"
import { useToast } from "./useToast"

let useToastRef: ProviderContext

export const InitializeToast = () => {
  useToastRef = useToast()
  return null
}

type Message = Toast["message"]

const toast = (msg: Message, options: ToastOptions = {}) => {
  useToastRef.enqueueToast(msg, options)
}

export default {
  success(msg: Message, options: ToastOptions = {}) {
    toast(msg, { ...options, variant: "success" })
  },
  warning(msg: Message, options: ToastOptions = {}) {
    toast(msg, { ...options, variant: "warning" })
  },
  info(msg: Message, options: ToastOptions = {}) {
    toast(msg, { ...options, variant: "info" })
  },
  error(msg: Message, options: ToastOptions = {}) {
    toast(msg, { ...options, variant: "error" })
  },
}
