import React from "react"

const ErrorScreen = ({ error = "" }) => (
  <div
    style={{
      fontFamily: "Inter",
      height: "calc(100vh - 16px)",
      display: "flex",
      padding: "24px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <p style={{ fontSize: "24px", marginBottom: 0, fontWeight: 600 }}>
      {error}
    </p>

    <p style={{ fontSize: "18px", marginTop: "12px" }}>
      <span style={{ color: "#7D7D7D" }}>
        If the issue persists then please contact{" "}
      </span>
      <a href="mailto:care@suraasa.com">care@suraasa.com</a>
    </p>
  </div>
)

export default ErrorScreen
