import { CircularProgress } from "@suraasa/placebo-ui"
import { ReactComponent as Logo } from "assets/logos/suraasa-colored-small.svg"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  progressOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.dialog,
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(0.5),
  },
}))

type Props = {
  /**
   * if true renders `Suraasa Butterfly` instead of `CircularProgress`
   */
  showLogo?: boolean
}

const LoadingOverlay: React.FC<Props> = ({ children, showLogo = false }) => {
  const classes = useStyles()

  return (
    <div className={classes.progressOverlay}>
      {showLogo ? <Logo /> : <CircularProgress />}
      {children}
    </div>
  )
}

export default LoadingOverlay
