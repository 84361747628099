import React from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { Tab, Tabs as TabsType } from "utils/hooks/useTabs"

import TabItem from "./TabItem"

const useStyles = createUseStyles(theme => ({
  tabContainer: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  tab: {
    ...theme.typography.body,
    color: theme.colors.onSurface[500],
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 0.25, 1, 0.25),

    "&$active": {
      ...theme.typography.strong,
      color: theme.colors.primary[500],
      borderBottom: `2px solid ${theme.colors.primary[500]}`,
    },
  },
  active: {},
}))

type Props = {
  tabs: TabsType
  activeTab: Tab
  onChange: (arg0: string) => void
  className?: string
}

const Tabs = ({ tabs, activeTab, onChange, className }: Props) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.tabContainer, className)}>
      {tabs.map(tab => (
        <TabItem
          isActive={tab.name === activeTab.name}
          key={tab.name}
          onClick={() => onChange(tab.name)}
        >
          {tab.content}
        </TabItem>
      ))}
    </div>
  )
}

export default Tabs
