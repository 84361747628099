import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Action, AuthData } from "./types"
import { urls } from "./urls"

export default {
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.generatePlatformToken(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  userActions: {
    list: async (): Promise<APIResponse<Action[]>> => {
      try {
        const res = await axios.get(urls.auth.listUserActions())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
