import React, { useContext, useEffect, useState } from "react"

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { Cancel, Edit } from "iconoir-react"

import api from "api"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

import AddGalleryImage from "./AddGalleryImage"

const useStyles = createUseStyles(theme => ({
  galleryDialogContent: {
    minHeight: "147px",
  },

  imageContainer: {
    width: "100%",
    height: "147px",
    position: "relative",
  },

  image: {
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
    zIndex: 10,
  },

  deleteButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
    width: "20px",
    height: "20px",
    zIndex: 11,
    borderRadius: "100px",
    background: theme.colors.critical[400],
    color: "white",

    "&:hover": {
      background: theme.colors.critical[500],
    },
  },

  addGalleryButton: {
    minHeight: "147px",
  },

  dialogTitle: {
    "& .DialogTitle-container > div": {
      width: "100%",

      "& > p": {
        width: "99%",
      },
    },
  },
}))

function EditGalleryDialog({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) {
  const classes = useStyles()
  const { gallery, isPublic, updateProfile, profile } =
    useContext(ProfileContext)
  const [editGallery, setEditGallery] = useState(false)
  const [isBeingRemoved, setIsBeingRemoved] = useState(false)

  useEffect(() => {
    if (!open) {
      setEditGallery(false)
    }
  }, [open])

  const handleRemove = async (id: number) => {
    setIsBeingRemoved(true)

    const res = await api.profile.gallery.delete({
      urlParams: { id },
    })

    if (res.isSuccessful) {
      gallery.remove(id)
      updateProfile({ galleryCount: profile.galleryCount - 1 })
    } else toast.error("Remove Image Failed")

    setIsBeingRemoved(false)
  }

  return (
    <Dialog
      className={clsx(classes.dialogTitle)}
      open={open}
      width="min(95%,800px)"
      onRequestClose={handleClose}
    >
      <DialogTitle>
        <div
          className={clsx(
            classes.dialogTitle,
            "flex items-center justify-between"
          )}
        >
          Gallery
          {editGallery ? (
            <Button
              variant="text"
              onClick={() => {
                setEditGallery(false)
              }}
            >
              Done
            </Button>
          ) : (
            <Button
              disabled={gallery.data.length === 0}
              startAdornment={<Edit />}
              variant="text"
              onClick={() => setEditGallery(true)}
            >
              Edit Gallery
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={clsx(
            "sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid gap-2",
            classes.galleryDialogContent
          )}
        >
          {gallery.data.length < 20 && !(isPublic || editGallery) && (
            <div className={classes.addGalleryButton}>
              <AddGalleryImage />
            </div>
          )}

          {gallery.data.map(item => (
            <div className={classes.imageContainer} key={item.id}>
              <img alt="gallery" className={classes.image} src={item.photo} />
              {editGallery && (
                <IconButton
                  className={classes.deleteButton}
                  disabled={isBeingRemoved}
                  onClick={() => handleRemove(item.id)}
                >
                  <Cancel height="16px" width="16px" />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditGalleryDialog
