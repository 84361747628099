import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { ArrowRight, Minus } from "iconoir-react"

const useStyles = createUseStyles(theme => ({
  card: {
    border: `1px solid ${theme.colors.surface[300]}`,
    borderRadius: "4px",
    background: theme.colors.common.white[500],
  },
  rotatePositive: {
    transform: "rotate(-45deg)",
  },
  rotateNegative: {
    transform: "rotate(45deg)",
  },
}))

type Props = {
  title: string
  timePeriod: string
  metric: number
  percentChange?: number | null
  percentChangePeriod?: string
  hidePercentChange?: boolean
}

const AnalyticCard = ({
  title,
  metric,
  timePeriod,
  percentChange,
  percentChangePeriod,
  hidePercentChange,
}: Props) => {
  const classes = useStyles()

  const getColor = () => {
    if (!percentChange) return "secondary.400"

    if (percentChange > 0) return "success.500"
    return "critical.500"
  }

  const getIcon = () => {
    if (!percentChange) return <Minus />
    return (
      <ArrowRight
        className={clsx({
          [classes.rotatePositive]: percentChange > 0,
          [classes.rotateNegative]: percentChange < 0,
        })}
      />
    )
  }

  return (
    <div className={clsx(classes.card, "p-3 rounded")}>
      <Typography variant="strong">{title}</Typography>
      <Typography className="mb-2" color="onSurface.500" variant="smallBody">
        {timePeriod}
      </Typography>
      <Typography display="inline" variant="title2">
        {metric}
      </Typography>
      {percentChange !== undefined && percentChange !== null && (
        <>
          <Typography
            className="ml-0.75"
            color={getColor()}
            display="inline"
            variant="smallBody"
          >
            <span className="inline-flex items-center">
              {!hidePercentChange && `${percentChange}% `}
              {getIcon()}
            </span>
          </Typography>

          {percentChangePeriod && (
            <Typography color="onSurface.400" variant="smallBody">
              {percentChangePeriod}
            </Typography>
          )}
        </>
      )}
    </div>
  )
}

export default AnalyticCard
