import axios from "api/axios"
import {
  APIResponse,
  Config,
  NoContentType,
  PaginatedAPIResponse,
  URLParams,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  AssignedSchool,
  CreateSchool,
  DashboardAnalytics,
  SalesSchoolProfile,
  School,
} from "./types"
import { urls } from "./urls"

export default {
  assignRandomSchool: async (): Promise<APIResponse<School>> => {
    try {
      const res = await axios.post(urls.verification.schools())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getAssignedSchool: async (): Promise<APIResponse<School>> => {
    try {
      const res = await axios.get(urls.verification.schools())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createNewSchool: async ({
    data,
  }: Config): Promise<APIResponse<CreateSchool>> => {
    try {
      const res = await axios.post(urls.verification.createSchool(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getDashboardAnalytics: async (): Promise<APIResponse<DashboardAnalytics>> => {
    try {
      const res = await axios.get(urls.dashboardAnalytics())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listAccessibleSchools: async (): Promise<APIResponse<School[]>> => {
    try {
      const res = await axios.get(urls.school.listAccessibleSchools())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  salesRep: {
    getSchoolProfiles: async ({
      params,
    }: Config): Promise<APIResponse<AssignedSchool[]>> => {
      try {
        const res = await axios.get(urls.salesRep.profile(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getSemiVerifiedProfiles: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<SalesSchoolProfile[]>> => {
      try {
        const res = await axios.get(urls.salesRep.semiVerified(), { params })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    assignProfile: async ({
      urlParams,
    }: URLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(urls.salesRep.base(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    submitProfile: async ({
      urlParams,
    }: URLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(urls.salesRep.base(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
