import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)
const getGravityURL = getServiceURL(ServicePrefix.gravity)

export const urls = validateUrls({
  profile: {
    retrieve: id => `/jobs/school-verification/profile/${id}/`,
    update: id => getNebulaURL(`/v1/school-verification/profile/${id}/`),
    submit: id =>
      getNebulaURL(`/v1/school-verification/initial-verifier/schools/${id}/`),
  },
  managementMessage: {
    base: id =>
      getNebulaURL(`/v1/school-verification/profile/${id}/management-message/`),
  },

  perks: {
    list: () => getNebulaURL("/v1/schools/perks/"),
    update: id => getNebulaURL(`/v1/school-verification/profile/${id}/perks/`),
    delete: (id, perkId) =>
      getNebulaURL(`/v1/school-verification/profile/${id}/perks/${perkId}/`),
  },
  amenities: {
    list: () => getNebulaURL("/v1/schools/amenities/"),
    update: id =>
      getNebulaURL(`/v1/school-verification/profile/${id}/amenities/`),
    delete: (id, amenityId) =>
      getNebulaURL(
        `/v1/school-verification/profile/${id}/amenities/${amenityId}/`
      ),
  },

  gallery: {
    list: id => getNebulaURL(`/v1/school-verification/profile/${id}/gallery/`),
    update: id =>
      getNebulaURL(`/v1/school-verification/profile/${id}/gallery/`),
    delete: id => getNebulaURL(`/v1/school-verification/gallery/${id}/`),
  },
  curricula: {
    create: () => getGravityURL("/curricula/create"),
  },
  changeEmail: profileId => `/jobs/initial-schools/${profileId}/admin/`,
})
