import React, { useContext, useState } from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { Edit, Plus } from "iconoir-react"

import api from "api"
import { tabsProfile } from "utils/constants"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

import AddGalleryImage from "./AddGalleryImage"
import EditGalleryDialog from "./EditGalleryDialog"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
  },

  imgContainer: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(0.5),
    width: "160px",
    height: "160px",
    objectFit: "cover",
  },
  floatingViewButton: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,

    "& > button": {
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
  },
}))

function Gallery() {
  const classes = useStyles()
  const {
    gallery,
    isPublic,
    profile,
    profile: { id },
  } = useContext(ProfileContext)

  const [openEditGalleryDialog, setOpenEditGalleryDialog] = useState(false)
  const showViewAllImagesButton = gallery.data.length < profile.galleryCount
  const [loading, setLoading] = useState(false)

  const getAllPictures = async () => {
    if (gallery.data.length === profile.galleryCount) {
      return
    }

    setLoading(true)

    const res = await api.profile.gallery.list({
      urlParams: { id },
    })

    if (res.isSuccessful) {
      gallery.set(res.data)
    } else {
      toast.error(res.errors.message || "Something went Wrong")
    }

    setLoading(false)
  }

  return (
    <div
      className={clsx(
        "px-2 pt-3.5 pb-6 sm:px-5 sm:py-4 mt-3",
        classes.container
      )}
      id={tabsProfile.gallery}
    >
      <div className="flex items-center justify-between">
        <EditGalleryDialog
          handleClose={() => {
            setOpenEditGalleryDialog(false)
          }}
          open={openEditGalleryDialog}
        />

        <Typography variant="title3">Gallery</Typography>

        {!isPublic && (
          <>
            {profile.galleryCount > 0 ? (
              /* TODO Change OnClick After making image viewer */
              <Button
                startAdornment={<Edit />}
                variant="text"
                onClick={() => {
                  getAllPictures()
                  setOpenEditGalleryDialog(true)
                }}
              />
            ) : (
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => {
                  getAllPictures()
                  setOpenEditGalleryDialog(true)
                }}
              >
                Add Photos
              </Button>
            )}
          </>
        )}
      </div>

      {gallery.data.length > 0 ? (
        <div className="flex gap-4 my-3 flex-wrap">
          {gallery.data.map((item, index) => (
            <img
              alt="school"
              className={classes.imgContainer}
              key={index}
              src={item.photo}
            />
          ))}
        </div>
      ) : (
        <div className="mt-3 mb-4 sm:mb-0">
          {isPublic ? (
            <Typography>No photos available</Typography>
          ) : (
            <AddGalleryImage height="89px" iconSize="32px" width="131px" />
          )}
        </div>
      )}

      {showViewAllImagesButton && (
        <>
          <div className="hidden sm:flex justify-end ">
            <Button
              loading={loading}
              variant="text"
              onClick={() => {
                getAllPictures()
              }}
            >
              View All Photos
            </Button>
          </div>

          <div className={clsx(classes.floatingViewButton, "sm:hidden")}>
            <Button
              loading={loading}
              variant="filled"
              fullWidth
              onClick={() => {
                getAllPictures()
              }}
            >
              View All Photos
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Gallery
