import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams, URLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Curriculum } from "../global/types"

import { Amenity, GalleryItem, ManagementMessage, Perk, Profile } from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.retrieve(urlParams.id), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.put(urls.profile.update(urlParams.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  submit: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<"">> => {
    try {
      const res = await axios.put(urls.profile.submit(urlParams.id))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  managementMessage: {
    update: async ({
      data,
      headers,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<ManagementMessage>> => {
      try {
        const res = await axios.post(
          urls.managementMessage.base(urlParams.id),
          data,
          {
            headers,
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams): Promise<APIResponse<ManagementMessage>> => {
      try {
        const res = await axios.delete(
          urls.managementMessage.base(urlParams.id)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  perks: {
    list: async (): Promise<APIResponse<Perk[]>> => {
      try {
        const res = await axios.get(urls.perks.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<{ perk: Perk }>> => {
      try {
        const res = await axios.post(urls.perks.update(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id" | "perkId">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(
          urls.perks.delete(urlParams.id, urlParams.perkId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  amenities: {
    list: async (): Promise<APIResponse<Amenity[]>> => {
      try {
        const res = await axios.get(urls.amenities.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<{ amenity: Amenity }>> => {
      try {
        const res = await axios.post(urls.amenities.update(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id" | "amenityId">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(
          urls.amenities.delete(urlParams.id, urlParams.amenityId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  gallery: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<GalleryItem[]>> => {
      try {
        const res = await axios.get(urls.gallery.list(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(urls.gallery.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<GalleryItem[]>> => {
      try {
        const res = await axios.post(urls.gallery.update(urlParams.id), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  curricula: {
    create: async ({ data }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.post(urls.curricula.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  changeEmail: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.put(urls.changeEmail(urlParams.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
