import { CssBaseline, theme } from "@suraasa/placebo-ui"
import GlobalState from "GlobalState"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"

import CheckAccess from "components/auth/CheckAccess"
import GetActions from "components/auth/GetActions"
import GetNewProfile from "components/SR/GetNewProfile"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
import SSO from "views/auth/SSO"
import CreateSchool from "views/IV/CreateSchool"
import IVHome from "views/IV/Home"
import Profile from "views/Profile"
import EditProfile from "views/Profile/Edit"
import SRHome from "views/SR/Home"

const PrivateRoute = () => {
  const auth = getAuthInfo()
  return auth ? <Outlet /> : <Navigate to={routes.sso} replace />
}

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ToastProvider domRoot={document.body}>
          <CssBaseline />
          <GlobalState>
            <Routes>
              <Route element={<SSO />} path={routes.sso} />

              <Route element={<PrivateRoute />} path="/">
                <Route
                  element={<Navigate to={routes.IV.home} replace />}
                  path={routes.home}
                />
                <Route element={<GetActions />} path={routes.home}>
                  {/* Routes for Sales Rep */}
                  <Route
                    element={<CheckAccess platformRole="SR" />}
                    path={routes.SR.home}
                  >
                    <Route element={<SRHome />} path={routes.SR.home} />
                    <Route
                      element={<div>Assigned</div>}
                      path={routes.SR.assigned}
                    />
                    <Route
                      element={<GetNewProfile />}
                      path={routes.SR.getNewProfile}
                    />
                  </Route>

                  {/* Routes for Initial Verifier */}
                  <Route
                    element={<CheckAccess platformRole="IV" />}
                    path={routes.IV.home}
                  >
                    <Route element={<IVHome />} path={routes.IV.home} />
                    <Route
                      element={<CreateSchool />}
                      path={routes.IV.createNewSchool}
                    />
                  </Route>

                  {/* Common pages */}
                  <Route element={<Profile />} path={routes.profile} />
                  <Route element={<EditProfile />} path={routes.editProfile} />
                </Route>
              </Route>
            </Routes>
          </GlobalState>
        </ToastProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
