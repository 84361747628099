import React from "react"

type Props = {
  limit?: number
  disabled?: boolean
  onChange: (files: File[]) => void
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "onChange" | "className" | "type"
>

/**
 * TODO: Add file size validation
 * TODO: Add file format validation
 */
const FileInput = React.forwardRef<HTMLInputElement, Props>(
  ({ limit = 1, disabled = false, onChange, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length !== 0) {
        return onChange(Array.from(e.target.files as FileList).slice(0, limit))
      }
    }

    return (
      <input
        disabled={disabled}
        multiple={limit > 1}
        {...props}
        className="hidden"
        ref={ref}
        type="file"
        onChange={handleChange}
      />
    )
  }
)

FileInput.displayName = "FileInput"

export default FileInput
