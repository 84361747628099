import React, { useEffect } from "react"

import { Button, theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { ArrowLeft } from "iconoir-react"

const useStyles = createUseStyles({
  mobileNavigation: {
    height: "51px",
    borderBottom: `1px solid  ${theme.colors.onSurface[200]}`,
    position: "fixed",
    top: 0,
    left: 0,
    background: theme.colors.common.white[500],
    width: "100vw",
    zIndex: 99,
  },
})

function MobileMenu({
  onBack,
  title,
  onSave,
  loading,
}: {
  onBack?: () => void
  title: string
  onSave?: () => void
  loading?: boolean
}) {
  const classes = useStyles()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  useEffect(() => {
    const nav = document.querySelector("nav")
    if (nav) {
      if (isXs) {
        nav.style.display = "none"
      } else {
        nav.style.display = "flex"
      }
    }
  }, [isXs])

  return (
    <div
      className={clsx(
        classes.mobileNavigation,
        "sm:hidden flex items-center px-1.5 justify-between -mb-5"
      )}
    >
      <div className="flex items-center gap-1.25">
        <ArrowLeft onClick={onBack} />
        <Typography color="common.black.700" variant="body">
          {title}
        </Typography>
      </div>

      {onSave && (
        <Button
          color="primary"
          loading={loading}
          variant="text"
          onClick={onSave}
        >
          Save
        </Button>
      )}
    </div>
  )
}

export default MobileMenu
