import React, { useContext, useEffect, useState } from "react"

import { GlobalContext } from "GlobalState"
import { Outlet } from "react-router-dom"

import api from "api"
import ErrorScreen from "components/ErrorScreen"
import LoadingOverlay from "components/shared/LoadingOverlay"

const GetActions = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { actions } = useContext(GlobalContext)

  useEffect(() => {
    const listActions = async () => {
      const res = await api.users.userActions.list()
      if (res.isSuccessful) {
        actions.set(res.data)
      } else {
        setError(res.errors.message ?? "Something went wrong")
      }
      setLoading(false)
    }
    listActions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingOverlay showLogo />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }
  return <Outlet />
}

export default GetActions
