import { Button, Container } from "@suraasa/placebo-ui"
import { ReactComponent as MiniLogo } from "assets/logos/suraasa-colored-small.svg"
import { ReactComponent as Logo } from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { getLearnURL } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    backgroundColor: "white",
  },
  containerRoot: {
    width: "100%",
  },
  libraryBtn: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
}))

export type NavbarProps = {
  slotEnd?: React.ReactElement
  className?: string
  gutterBottom?: boolean
}

const Navbar = ({ slotEnd, className, gutterBottom = true }: NavbarProps) => {
  const classes = useStyles()

  const isLoggedIn = getAuthInfo()

  return (
    <nav
      className={clsx(classes.root, "py-1 flex items-center", className, {
        "mb-6": gutterBottom,
      })}
    >
      <Container className={classes.containerRoot}>
        <div className={clsx(classes.container)}>
          <a className="md:hidden" href={getLearnURL("/")}>
            <MiniLogo height="40" width="50" />
          </a>
          <a className="hidden md:block" href={getLearnURL("/")}>
            <Logo width="150px" />
          </a>
          <div className="flex gap-1">
            {slotEnd && <div>{slotEnd}</div>}
            {isLoggedIn && (
              <div className="flex items-center sm:gap-1.5">
                <Button
                  className={classes.libraryBtn}
                  variant="text"
                  onClick={() => {
                    clearAuthInfo()
                    const url = new URL(
                      `${process.env.REACT_APP_SSO_URL}/logout`
                    )
                    url.searchParams.append(
                      "origin",
                      `${window.location.origin}`
                    )
                    url.searchParams.append(
                      "platform",
                      "School Verification Portal"
                    )
                    window.location.href = url.href
                  }}
                >
                  Logout
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default Navbar
