import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  auth: {
    refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),
    listUserActions: () => getSolisURL(`/v1/auth/actions/`),
    generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  },
})
