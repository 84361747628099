import { IconButton, TextField } from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"

import { Search } from "iconoir-react"

const SearchField = ({ onSearch }: { onSearch: (search: string) => void }) => {
  const { register, handleSubmit } = useForm<{ search: string }>()

  const onSubmit = handleSubmit(formData => {
    onSearch(formData.search)
  })

  return (
    <form className="flex gap-1 items-center" onSubmit={onSubmit}>
      <TextField
        placeholder="Search by school name"
        startIcon={<Search />}
        {...register("search")}
      />
      <IconButton size="md" type="submit" variant="filled">
        <Search />
      </IconButton>
    </form>
  )
}

export default SearchField
